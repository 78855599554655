<template>
  <svg
    width="12"
    height="16"
    viewBox="0 0 12 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="currentColor"
      d="M9.5 0H2.5C1.39531 0 0.5 0.895313 0.5 2V14C0.5 15.1047 1.39531 16 2.5 16H9.5C10.6047 16 11.5 15.1047 11.5 14V2C11.5 0.895313 10.6031 0 9.5 0ZM10 14C10 14.2757 9.77569 14.5 9.5 14.5H2.5C2.22438 14.5 2 14.275 2 14V2C2 1.72431 2.22431 1.5 2.5 1.5H9.5C9.775 1.5 10 1.72438 10 2V14ZM7 12.5H5C4.72388 12.5 4.5 12.7239 4.5 13C4.5 13.2761 4.72388 13.5 5 13.5H7C7.27619 13.5 7.5 13.2761 7.5 13C7.5 12.7239 7.275 12.5 7 12.5Z"
    />
  </svg>
</template>
